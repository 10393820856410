import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonButton,
  IonModal,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonGrid,
  IonRow,
  IonIcon,
  IonHeader,
} from '@ionic/react'
import TextArea from './TextArea'
import { closeOutline } from 'ionicons/icons'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from 'components'

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
  setNote: PropTypes.func.isRequired,
}

const defaultProps = {}

function NoteModal({ closeModal, isModalOpen, note, setNote }) {
  const validationSchema = Yup.object({
    note: Yup.string(),
  })

  return (
    <IonModal
      backdropDismiss={false}
      className="custom-modal"
      isOpen={isModalOpen}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Note</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="dark"
              onClick={closeModal}
              aria-label="Cancel adding or editing a note"
            >
              <IonIcon icon={closeOutline} slot="icon-only" color="dark" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid>
        <Formik
          initialValues={{ note }}
          onSubmit={({ note }) => {
            setNote(note)
            closeModal()
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          enableReinitialize
        >
          <Form noValidate>
            <IonRow className="ion-justify-content-center modal-row-with-larger-margin">
              <TextArea
                name="note"
                placeholder="Enter note..."
                label={false}
                aria-label="Note"
                counter={true}
                maxlength={250}
                counterFormatter={(inputLength, maxLength) =>
                  `${maxLength - inputLength} characters remaining`
                }
              />
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <SubmitButton expand="block" color="secondary">
                Save Changes
              </SubmitButton>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonButton
                expand="block"
                fill="outline"
                color="dark"
                onClick={() => closeModal()}
              >
                Cancel
              </IonButton>
            </IonRow>
          </Form>
        </Formik>
      </IonGrid>
    </IonModal>
  )
}

NoteModal.propTypes = exact(propTypes)
NoteModal.defaultProps = defaultProps

export default React.memo(NoteModal)
