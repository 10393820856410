import { useState, useEffect } from 'react'
import { useIonViewWillEnter, isPlatform } from '@ionic/react'
import { registerPlugin } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import { useToast } from 'hooks'
import { getTimeZone } from 'utils'
import {
  getLocalStorageTrip,
  getLocalStoragePosition,
  getLocalStorageWatcherId,
  setLocalStorageTrip,
  setLocalStoragePosition,
  setLocalStorageWatcherId,
  clearLocalStorageTrip,
  clearLocalStoragePosition,
  clearLocalStorageWatcherId,
} from '../services/mileageTrackerStorage'
import { APP_PATH, PERMISSION } from 'config'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as apiActions from 'api-actions'
import { omit } from 'lodash'

const BackgroundGeolocation = registerPlugin('BackgroundGeolocation')

const GEOLOCATION_OPTIONS = {
  backgroundMessage:
    'Please make sure your device is connected to a power source or its battery is fully charged.',
  backgroundTitle: 'The app is tracking your location in the background.',
  requestPermissions: true,
  distanceFilter: 10, // in meters
}

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8 // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in miles
  return d
}

const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

const initialTrip = {
  miles: 0,
  startTime: null,
  endTime: null,
  startTimeZone: null,
  endTimeZone: null,
}

function useMileageTracker() {
  const [trip, setTrip] = useState(null)
  const [isSettingWatcher, setIsSettingWatcher] = useState(false)
  const [isSavingCompletedTrip, setIsSavingCompletedTrip] = useState(false)
  const [unsavedTrip, setUnsavedTrip] = useState(null)
  const { showFailureToast, showWarningToast, showSuccessToast } = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  useIonViewWillEnter(() => {
    const existingTrip = getLocalStorageTrip()
    if (existingTrip) setTrip(existingTrip)
    else resetWatchData()
  })

  useEffect(() => {
    // Subscribe to changes in local storage (relies on window.dispatchEvent) to ensure the local state 'trip' is synced to the local storage version
    const syncTrip = () => {
      setTrip(getLocalStorageTrip())
    }
    window.addEventListener('storage', syncTrip)

    return () => {
      window.removeEventListener('storage', syncTrip)
    }
  }, [])

  const setTripStart = () => {
    const newTrip = {
      ...initialTrip,
      startTime: new Date(),
      startTimeZone: getTimeZone(),
    }
    setLocalStorageTrip(newTrip)
  }

  const resetWatchData = () => {
    const watcherId = getLocalStorageWatcherId()
    if (watcherId) BackgroundGeolocation.removeWatcher({ id: watcherId })
    clearLocalStoragePosition()
    clearLocalStorageWatcherId()
  }

  const showFailureToastForStartTracking = (errorMessage) => {
    showFailureToast({
      message: `There was an issue starting your trip: ${errorMessage}. Please try again.`,
      callback: () => handleStart(),
    })
  }

  const updateMileage = (location) => {
    const lastLocation = getLocalStoragePosition()
    const existingTrip = getLocalStorageTrip()

    const tripWithEndTime = {
      ...existingTrip,
      // Set current time as end time to ensure the trip has an end time for live updates, allowing the last reported location to be saved if the trip saving fails.
      endTime: new Date(),
      endTimeZone: getTimeZone(),
    }

    if (!lastLocation) {
      saveInProgressTrip(tripWithEndTime, location)
      return
    }

    const distance = calculateDistance(
      lastLocation.latitude,
      lastLocation.longitude,
      location.latitude,
      location.longitude
    )
    if (distance > 0) {
      const updatedTrip = {
        ...tripWithEndTime,
        miles: existingTrip.miles + distance,
      }
      setLocalStorageTrip(updatedTrip)
      // Save trip and location roughly every 5 minutes
      if (location.time >= existingTrip.lastUpdateSent + 5 * 60 * 1000) {
        saveInProgressTrip(updatedTrip, location)
      }
    }
  }

  const checkNotificationPermissions = async () => {
    if (!isPlatform('android')) return true

    // Android 13+ needs permission to show notification to the user that their location is being used in the background
    let permissions = await LocalNotifications.checkPermissions()
    if (permissions.display === PERMISSION.PROMPT) {
      permissions = await LocalNotifications.requestPermissions()
    }
    return permissions.display !== PERMISSION.DENIED
  }

  const handleStart = async () => {
    try {
      const hasPermission = await checkNotificationPermissions()
      if (!hasPermission) {
        history.push(APP_PATH.MILEAGE_TRACKER.NOTIFICATION_PERMISSION_NEEDED)
        return
      }
      setIsSettingWatcher(true)
      const watcherId = await BackgroundGeolocation.addWatcher(
        GEOLOCATION_OPTIONS,
        // This callback function gets continuously re-run on location change
        (location, error) => {
          setIsSettingWatcher(false)
          if (error) {
            // Despite the error state, the plugin seems to add the watcher anyway, so manually remove it:
            resetWatchData()
            if (error.code === 'NOT_AUTHORIZED') {
              history.push(APP_PATH.MILEAGE_TRACKER.LOCATION_PERMISSION_NEEDED)
              return
            }
            return showFailureToastForStartTracking(error.message)
          }

          const existingTrip = getLocalStorageTrip()
          if (!existingTrip) setTripStart()
          updateMileage(location)
          setLocalStoragePosition(location)
        }
      )
      setLocalStorageWatcherId(watcherId)
    } catch (error) {
      showFailureToastForStartTracking(error.message)
      setIsSettingWatcher(false)
    }
  }

  const handleStop = () => {
    const existingTrip = getLocalStorageTrip()
    if (existingTrip.miles === 0 && !existingTrip.id) {
      showWarningToast({
        message: 'Trip was not saved since 0 miles were tracked.',
      })
      resetWatchData()
      clearLocalStorageTrip()
      return
    }
    const updatedTrip = {
      ...existingTrip,
      endTime: new Date(),
      endTimeZone: getTimeZone(),
    }
    setLocalStorageTrip(updatedTrip)
    const lastLocation = getLocalStoragePosition()
    saveCompletedTrip(updatedTrip, lastLocation)
  }

  const saveTrip = async (trip, location = null, { completed = true } = {}) => {
    const tripToSave = {
      ...omit(trip, ['lastUpdateSent', 'id']),
      inProgress: !completed,
    }
    const locationToSave = location
      ? {
          ...location,
          time: new Date(location.time),
        }
      : null
    // If the trip object lacks an ID, the trip hasn't been created in the backend yet.
    if (!trip.id)
      return await dispatch(
        apiActions.createTrip({ trip: tripToSave, location: locationToSave })
      )
    // If the trip object includes an ID, the trip has been created in the backend.
    return await dispatch(
      apiActions.updateTrip(
        { trip: tripToSave, location: locationToSave },
        trip.id
      )
    )
  }

  // Handles saving a completed trip when the user clicks "Stop Tracking" or retries saving after an API failure.
  const saveCompletedTrip = async (trip, location = null) => {
    try {
      setIsSavingCompletedTrip(true)
      const savedTrip = await saveTrip(trip, location)
      showSuccessToast({
        message:
          'Your trip has been successfully saved! You may add a note and/or edit the trip.',
        duration: 6000,
      })
      history.push(`${APP_PATH.MILEAGE_TRACKER.EDIT_TRIP}/${savedTrip.id}`)
    } catch {
      setUnsavedTrip(trip) // Retain trip info for potential resubmission, but do not save the location.
      // On retrying, only the trip will be saved again and the location save will be skipped, which is why the location params is optional.
    } finally {
      setIsSavingCompletedTrip(false)
      resetWatchData()
      clearLocalStorageTrip()
    }
  }

  // Handles saving a trip when its first location is reported and every 5 minutes during an active trip.
  const saveInProgressTrip = async (trip, location) => {
    let id = trip.id
    // Update the last time a save attempt was made, to be used for periodic saves every 5 minutes.
    // Note: Cannot rely on setInterval due to Apple's "App Nap", which may suspend background processes: https://developer.apple.com/library/archive/documentation/Performance/Conceptual/power_efficiency_guidelines_osx/AppNap.html
    // Save the lastUpdateSent before we make the API call to avoid multiple calls in case of slow network:
    setLocalStorageTrip({
      ...getLocalStorageTrip(),
      lastUpdateSent: Date.now(),
    })
    try {
      const savedTrip = await saveTrip(trip, location, { completed: false })
      id = savedTrip.id
    } catch {
      // Catch and handle errors silently, as failure to save shouldn't stop the tracking process.
    } finally {
      setLocalStorageTrip({
        ...getLocalStorageTrip(),
        // If the trip is newly created, store the ID in local storage. This helps determine whether subsequent saves should use create or update.
        id,
      })
    }
  }

  return {
    trip,
    handleStart,
    handleStop,
    isSavingCompletedTrip,
    isSettingWatcher,
    unsavedTrip,
    saveCompletedTrip,
    clearUnsavedTrip: () => setUnsavedTrip(null),
  }
}

export default useMileageTracker
