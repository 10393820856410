import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { IonButton } from '@ionic/react'

const propTypes = {
  handleClick: PropTypes.func.isRequired,
}

const defaultProps = {}

function HubMessageArchiveButton({ handleClick }) {
  return (
    <IonButton
      color="dark"
      className="action-button hub-message-button"
      onClick={handleClick}
    >
      Archive
    </IonButton>
  )
}

HubMessageArchiveButton.propTypes = exact(propTypes)
HubMessageArchiveButton.defaultProps = defaultProps

export default React.memo(HubMessageArchiveButton)
