import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonButton } from '@ionic/react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik, Field } from 'formik'
import { TextInput, SubmitButton, NoteModal } from 'components'
import { Cropper } from 'react-cropper'
import * as Yup from 'yup'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { isEmpty } from 'lodash'
import { formatFilesPayloadFromValues } from 'utils'
import { useToast } from 'hooks'
import * as apiActions from 'api-actions'
import { UPLOAD_FLOW_TYPE, UPLOAD_PAGE_TYPE, APP_PATH } from 'config'

const propTypes = {
  uploadedPhoto: Types.photo.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setCaptureMethod: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  postDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

function CameraFlow({
  uploadedPhoto,
  addFileToDocument,
  setCaptureMethod,
  setTitle,
  title,
  postDocument,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [note, setNote] = useState(uploadedPhoto.note ?? '')
  const { showFailureToast } = useToast()
  const history = useHistory()
  const { pageType, hubMessageId } = useParams()
  const cropperRef = useRef()
  const [croppedBlob, setCroppedBlob] = useState()
  const isMultipleSelection = pageType === UPLOAD_PAGE_TYPE.MULTIPLE.value

  const onCrop = () => {
    if (!cropperRef.current) return

    const cropper = cropperRef.current.cropper
    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        if (!blob) {
          return showFailureToast({
            message:
              'Issue saving this image. Please try again or take a new photo.',
          })
        }
        setCroppedBlob(blob)
      },
      uploadedPhoto.mimeType ?? `image/${uploadedPhoto.format}`,
      0.3
    )
  }

  const validationSchema = Yup.object({
    title: Yup.string(),
    hubMessageId: Yup.string(),
  })

  const handleSubmit = async (values, { resetForm }) => {
    if (!isMultipleSelection) {
      // if single page selected, send call immediately and direct to success splash
      try {
        const valuesWithFiles = {
          ...values,
          files: [
            {
              blob: croppedBlob,
              fileName: uploadedPhoto.fileName,
              note,
            },
          ],
        }
        const payload = formatFilesPayloadFromValues(valuesWithFiles)
        await postDocument(payload)

        resetForm()
        setNote('')
        history.push(
          `${APP_PATH.UPLOAD.SUCCESS}${hubMessageId ? `/${hubMessageId}` : ''}`
        )
      } catch (errorMessage) {
        showFailureToast({ message: errorMessage })
      }
    } else {
      // if multiple page selected, store uploadedDocument blob and form meta in redux to pass to DocumentManager page.
      addFileToDocument({
        note,
        blob: croppedBlob,
        fileName: uploadedPhoto.fileName,
      })
      setCaptureMethod(UPLOAD_FLOW_TYPE.CAMERA)
      setNote('')
      setTitle(values.title)
      history.push(
        `${APP_PATH.UPLOAD.DOCUMENT_MANAGER}${hubMessageId ? `/${hubMessageId}` : ''}`
      )
    }
  }

  return (
    <div className="crop-wrapper">
      <Formik
        initialValues={{ title, hubMessageId }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="ion-padding document-preview-state" noValidate>
            <Field type="hidden" name="hubMessageId" />

            {isEmpty(title) && (
              <TextInput
                name="title"
                label="Document Title (optional)"
                inputmode="text"
                color="light"
                disabled={isSubmitting}
              />
            )}
            <Cropper
              src={uploadedPhoto.webPath}
              initialAspectRatio={1 / 1.41}
              ready={onCrop} // used to save crop if no changes are made before submission
              cropend={onCrop}
              ref={cropperRef}
              alt="Captured photo with cropper"
              viewMode={2}
              dragMode="none"
              zoomOnWheel={false}
            />
            <IonButton
              id="open-note-modal"
              expand="block"
              fill="outline"
              color="dark"
              onClick={() => {
                setIsModalOpen(true)
              }}
              disabled={isSubmitting}
            >
              Add Note
            </IonButton>
            <SubmitButton color="secondary" submitting={isSubmitting}>
              {isMultipleSelection ? 'Save' : 'Submit'}
            </SubmitButton>
          </Form>
        )}
      </Formik>
      <NoteModal
        note={note}
        setNote={setNote}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  )
}

CameraFlow.propTypes = exact(propTypes)
CameraFlow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    title: selectors.title(state),
    uploadedPhoto: selectors.uploadedPhoto(state),
  }
}

const mapDispatchToProps = {
  addFileToDocument: actions.addFileToDocument,
  setCaptureMethod: actions.setCaptureMethod,
  setTitle: actions.setTitle,
  postDocument: apiActions.postDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CameraFlow)
