import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonBackButton,
  IonTitle,
  IonIcon,
} from '@ionic/react'
import classNames from 'classnames'
import { Logo } from 'components'
import { APP_PATH } from 'config'
import menuIconWithNotifications from 'images/menu-with-notifications.svg'
import menuIconNoNotifications from 'images/menu.svg'
import { informationCircleOutline } from 'ionicons/icons'

const propTypes = {
  title: PropTypes.string.isRequired,
  hideBackButton: PropTypes.bool.isRequired,
  backHref: PropTypes.string.isRequired,
  logoPath: PropTypes.string,
  children: PropTypes.node,
  hasActiveHubMessages: PropTypes.bool.isRequired,
  infoModalTriggerId: PropTypes.string,
}
const defaultProps = {
  children: null,
  logoPath: APP_PATH.HOME,
  infoModalTriggerId: '',
}

function Header({
  title,
  hideBackButton,
  backHref,
  logoPath,
  children,
  hasActiveHubMessages,
  infoModalTriggerId,
}) {
  const menuButtonIcon = hasActiveHubMessages
    ? menuIconWithNotifications
    : menuIconNoNotifications
  return (
    <IonHeader
      className={classNames('main-header', {
        'force-dark-header': hideBackButton,
      })}
    >
      <div className="desktop-menu-bar ion-hide-md-down">
        <IonMenuButton aria-label="Open menu" color="light">
          <IonIcon icon={menuButtonIcon} />
        </IonMenuButton>
        <Logo path={logoPath} color="light" />
      </div>
      <IonToolbar className="with-header-icon">
        {hideBackButton ? (
          <IonButtons className="ion-hide-md-up" slot="start">
            <IonMenuButton>
              <IonIcon icon={menuButtonIcon} />
            </IonMenuButton>
          </IonButtons>
        ) : (
          <IonButtons slot="start">
            <IonBackButton defaultHref={backHref} />
          </IonButtons>
        )}
        <div
          className={classNames({
            'header-with-info': infoModalTriggerId,
          })}
        >
          <IonTitle role="heading" aria-level={1}>
            {title}
          </IonTitle>
          {infoModalTriggerId && (
            <IonButtons slot="primary">
              <IonButton
                id={infoModalTriggerId}
                fill="clear"
                size="small"
                aria-label={`Learn more about ${title}`}
                className="header-info-icon"
              >
                <IonIcon slot="icon-only" icon={informationCircleOutline} />
              </IonButton>
            </IonButtons>
          )}
        </div>
        {/* Any additional toolbar items can be added through children */}
        {children}
      </IonToolbar>
    </IonHeader>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default React.memo(Header)
