import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { clearSessionToken } from '../services/auth'
import * as actions from './actions'
import { selectors } from './reducer'
import { Redirect } from 'react-router-dom'
import * as apiActions from 'api-actions'
import * as Types from 'types'
import { LoadingSpinner } from 'components'
import { SplashScreen } from '@capacitor/splash-screen'
import { useRestrictedAccessRedirectPath } from 'hooks'
import { APP_PATH, isProduction } from 'config'
import SmartlookWebsite from 'smartlook-client'
import { Smartlook as SmartlookNativeApp } from '@awesome-cordova-plugins/smartlook'
import { isWebsite } from 'utils'

const propTypes = {
  fetchUserProfile: PropTypes.func.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  currentUser: Types.user,
  fetchHubMessages: PropTypes.func.isRequired,
}

const defaultProps = {
  currentUser: null,
}

function AuthenticatedLayout({
  fetchUserProfile,
  currentUser,
  clearCurrentUser,
  children,
  fetchHubMessages,
}) {
  const [error, setError] = useState()
  const hasLoaded = error || currentUser
  const redirectPath = useRestrictedAccessRedirectPath(currentUser)

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await fetchUserProfile()
      } catch (errorMessage) {
        setError(errorMessage)
      }
    }

    if (!currentUser) {
      // make call to test bearer token
      checkAuthentication()
    }
  }, [fetchUserProfile, currentUser])

  useEffect(() => {
    async function hideSplashScreen() {
      await SplashScreen.hide()
    }

    if (currentUser) {
      hideSplashScreen()
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && isProduction()) {
      const {
        constituentId,
        email,
        id,
        fullName,
        accountSfid,
        clientCustomerType,
      } = currentUser
      const userId = id.toString() // smartlook expects string values
      if (isWebsite()) {
        SmartlookWebsite.identify(userId, {
          name: fullName,
          email,
          constituentId,
          accountSfid,
          clientCustomerType,
        })
      } else {
        SmartlookNativeApp.setUserIdentifier({ identifier: userId })
        SmartlookNativeApp.setUserName({ name: fullName })
        SmartlookNativeApp.setUserEmail({ email })
        SmartlookNativeApp.setUserProperty({
          propertyName: 'constituentId',
          value: constituentId,
        })
        SmartlookNativeApp.setUserProperty({
          propertyName: 'accountSfid',
          value: accountSfid,
        })
        SmartlookNativeApp.setUserProperty({
          propertyName: 'clientCustomerType',
          value: clientCustomerType,
        })
      }
    }
  }, [currentUser])

  useEffect(() => {
    fetchHubMessages()

    // Set up interval to periodically fetch hub messages every 5 minutes (300000 ms)
    const intervalId = setInterval(() => {
      fetchHubMessages()
    }, 300000)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId)
  }, [fetchHubMessages])

  if (!hasLoaded) return <LoadingSpinner />

  if (error) {
    if (currentUser) clearCurrentUser()
    clearSessionToken()
    return <Redirect path="*" to={APP_PATH.SIGN_IN} />
  }

  if (redirectPath) return <Redirect to={redirectPath} />

  return children
}

AuthenticatedLayout.propTypes = propTypes
AuthenticatedLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: selectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchUserProfile: apiActions.fetchUserProfile,
  clearCurrentUser: actions.clearCurrentUser,
  fetchHubMessages: apiActions.fetchHubMessages,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthenticatedLayout
)
